export default {

	/** Calculate map height after form was rendered. height = bodyHeight - formHeight. Page should be without scroll */
	calculateMapHeight(mapContainer) {
		let result;
		const delta = 5;
		let entireHeight = document.querySelector('body').offsetHeight;
		let elementSiblings = this.getSiblings(mapContainer);

		let siblingHeight = 0;
		elementSiblings.forEach(element => {
			siblingHeight += element.offsetHeight;
		});

		result = entireHeight - siblingHeight - delta;
		return result;
	},

	getSiblings(elem) {
		// Setup siblings array and get the first sibling
		var siblings = [];
		var sibling = elem.parentNode.firstChild;

		// Loop through each sibling and push to the array
		while (sibling) {
			if (sibling.nodeType === 1 && sibling !== elem) {
				siblings.push(sibling);
			}
			sibling = sibling.nextSibling;
		}

		return siblings;
	},

	getHtmlRadio(data, options) {
		let html = `<span data-nls="${options.nlsAttributeValues.title}"></span>`;

		let array = data;

		// Save additional fields. They may be set on frontend side, eg 'All'
		if (options.additionalFields && options.additionalFields.length) {
			array = options.additionalFields.concat(data);
		}

		// by default select first element, if no selected index in options
		let indexToSelect = options.indexToSelect != undefined ? options.indexToSelect : 0;
		// Set nls, name, value attributes 
		array.forEach((element, index) => {
			if (element.Value != options.hiddenValue) {
				// console.log(element, index);
				if (index == indexToSelect) {
					html += `
							<label class="label">
								<span data-nls="${options.nlsAttributeValues.elements[element.ID]}">
									${element.Value}
								</span>
								<input type="radio" name=${options.name} value="${element.ID}" checked="checked">
								<span class="checkmark"></span>
							</label>`;
				}
				else {
					html += `
							<label class="label">
								<span data-nls="${options.nlsAttributeValues.elements[element.ID]}">
									${element.Value}
								</span>
								<input type="radio" name=${options.name} value="${element.ID}">
								<span class="checkmark"></span>
							</label>`;
				}
			}
		});

		return html;
	},

	getHtmlCheckbox(data, options) {
		let html = '';

		if (options.nlsAttributeValues) {
			html += `<span data-nls="${options.nlsAttributeValues.title}"></span>`;
		}

		let array = data;

		// Save additional fields. They may be set on frontend side, eg 'All'
		if (options.additionalFields && options.additionalFields.length) {
			array = options.additionalFields.concat(data);
		}

		// by default select first element, if no selected index in options
		let indexToSelect = options.indexToSelect;
		// Set nls, name, value attributes 
		array.forEach((element, index) => {
			if (element.Value != options.hiddenValue) {
				// console.log(element, index);
				if (index == indexToSelect) {
					html += `
							<label class="label">
								<span>
									${element.Value}
								</span>
								<input type="checkbox" name=${options.name} value="${element.ID}" checked="checked">
								<span class="checkbox"></span>
							</label>`;
				}
				else {
					html += `
							<label class="label">
								<span>
									${element.Value}
								</span>
								<input type="checkbox" name=${options.name} value="${element.ID}">
								<span class="checkbox"></span>
							</label>`;
				}
			}
		});

		return html;
	},

	getHtmlInput(data, options) {
		let html = `<span>${options.label}</span>`;
		let input = `<input id="${options.id}" name=${options.name} type="text"
		value="${options.defaultValue}" class="${options.css}">`
		html += input;
		return html;
	},

	getHtmlStringSelectNative(data, options) {
		let htmlOptions = ``;

		let array = data;
		if (options.additionalFields && options.additionalFields.length) {
			array = options.additionalFields.concat(data);
		}

		array.forEach((element, index) => {
			if (index === 0) {
				htmlOptions += `
						<option value="${element.ID}" selected>
							${element.Value.charAt(0).toUpperCase() + element.Value.slice(1)}
						</option>
						`;
			} else {

				// TODO improve
				if (element.Value == 'stc') {
					htmlOptions += `
						<option value="${element.ID}">
							${element.Value}
						</option>
						`;
				} else {
					htmlOptions += `
						<option value="${element.ID}">
							${element.Value.charAt(0).toUpperCase() + element.Value.slice(1)}
						</option>
						`;
				}
			}
		});

		let html = `<span data-nls="${options.nlsAttributeValues.title}"></span>
				<div class="custom-select">
					<select id=${options.id} name=${options.name}>
						${htmlOptions}
					</select>
                </div>`;
		return html;
	},

	getHtmlStringMonthPickerNative(data, options) {
		let html = `
			<span data-nls="${options.nlsAttributeValues.title}"></span>
			<div id="${options.id}" class="custom-month-picker"> 
				<input name="${options.nameStartDate}" type="text">
				<input name="${options.nameEndDate}" type="text">
			</div>`;

		return html;
	},

	getQueryParamsFromUI(form, elementsToSkip) {
		let result = {};

		for (let i = 0; i < form.elements.length; i++) {
			let e = form.elements[i];

			if (elementsToSkip != undefined && elementsToSkip.includes(e.name)) {
				result = Object.assign(result, {
					[e.name]: ''
				});
			}

			if (e.type === 'checkbox' && e.checked) {
				result = Object.assign(result, {
					[e.name]: e.value
				});
			}

			if (e.type === 'radio' && e.checked) {
				result = Object.assign(result, {
					[e.name]: e.value
				});
			}

			else if (e.type === 'select-one') {
				result = Object.assign(result, {
					[e.name]: e.value
				});
			}

			else if (e.type === 'text') {
				result = Object.assign(result, {
					[e.name]: e.value
				});
			}
		}

		// console.log(result);

		return result;
	},

	getMonthPickerData(minDate, months, debug) {
		let result = [];

		let maxDate = new Date();
		let monthsStart = minDate.getFullYear() * 12 + minDate.getMonth() + 1;

		// set last month to prev one
		let monthsEnd = maxDate.getFullYear() * 12 + maxDate.getMonth();
		let diff = monthsEnd - monthsStart;

		for (let index = 0; index <= diff; index++) {

			let firstDate = minDate.getDate();
			if (firstDate < 10) {
				firstDate = '0' + firstDate;
			}

			let month = minDate.getMonth() + 1;
			if (month < 10) {
				month = '0' + month;
			}
			const year = minDate.getFullYear();

			let lastDate = new Date(year, month, 0).getDate();

			result.push({
				index: index,
				label: `${months[month - 1]}, ${year}`,
				startDate: `${year}-${month}-${firstDate}`,
				endDate: `${year}-${month}-${lastDate}`,
				selected: false
			});

			minDate.setMonth(minDate.getMonth() + 1);

		}

		result.sort((a, b) => b.index - a.index);


		// HARDCODE for "Last 3 months", "Last 6 months" values
		result.push(
			// {
			// 	index: result.length,
			// 	label: `Last 3 months`,
			// 	startDate: `9999-03-01`,
			// 	endDate: `9999-03-01`,
			// 	selected: true
			// },
			{
				index: result.length,
				label: `6 derniers mois`,
				startDate: `9999-06-01`,
				endDate: `9999-06-01`,
				selected: true
			});

		return result;
	}
};