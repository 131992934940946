export default {
    titles: {
        default: '',
        networkCoverage: 'Carte de couverture',
        internetSpeeds: 'Vitesse Internet',
        usageTrends: 'Usage trends map',
    },
    buttons: {
        networkCoverage: 'Couverture réseau',
        internetSpeeds: 'Vitesse Internet',
        usageTrends: 'Usage trends',
    },
    inputs: {
        accessType: {
            title: "Type d'accès",
            wifi: 'Wifi',
            cellular: 'Mobile',
            both: 'les deux'
        },
        technology: {
            title: 'Technologie',
            '2g': '2G',
            '3g': '3G',
            '4g': '4G',
            '5g': '5G'
        },
        isp: {
            title: 'Opérateur',
        },
        administrativeLevels: {
            title: 'Divisions'
        },
        monthPicker: {
            title: 'Période',
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        },
        towers: {
            title: 'Montrer les Tours'
        }
    }
}