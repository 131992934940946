
require('normalize.css/normalize.css');
require('./styles/index.scss');
require('./js/popup.js');


import TRAUIMap from './js/ui-map.js';

document.addEventListener('DOMContentLoaded', () => {

    window.onerror = function (msg, url, lineNo, columnNo, error) {
        console.log(msg, url, lineNo, columnNo, error);
        return false;
    }
    init();
});

function init() {
    let iframeFolderUrl = location.origin + location.pathname.split('index.html')[0] + 'map-iframe';
    console.log('iframeFolderUrl', iframeFolderUrl);
    // by default create choropleth
    let map = new TRAUIMap();
    map.init({
        type: 'heat',

        // CUSTOMIZABLE PART
        iframeUrl: iframeFolderUrl + '/index.html',
        iframeFolderUrl: iframeFolderUrl
    });
}