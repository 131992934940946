export default {
    titles: {
        default: '',
        networkCoverage: 'خريطة تغطية الشبكة',
        internetSpeeds: 'خريطة سرعات الانترنت',
        usageTrends: 'خريطة اتجاهات الاستخدام',
    },
    buttons: {
        networkCoverage: 'تغطية الشبكة',
        internetSpeeds: 'سرعات الانترنت',
        usageTrends: 'اتجاهات الاستخدام',
    },
    inputs: {
        accessType: {
            title: 'نوع الوصول',
            wifi: 'واي فاي',
            cellular: 'خلوي'
        },
        technology: {
            title: 'تقنية',
            '2g': '2G',
            '3g': '3G',
            '4g': '4G',
            '5g': '5G'
        },
        isp: {
            title: 'مزود خدمة الإنترنت',
        },
        monthPicker: {
            title: 'فترة',
            months: ['كانون الثاني', 'شهر فبراير', 'مارس', 'أبريل', ' مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'اكتوبر', 'شهر نوفمبر', 'ديسمبر']
        }
    }
}