import apiController from './api.js';
import helpers from './helpers.js';
import nls from './nls/index.js';

// TODO add version parameter
// ? TODO add speedvalue type radiobox (download/upload)

const CSS_CHOROPLETH_ONLY = 'choropleth-only';

const MAP_TYPES = {
	HEAT: 'heat',
	CHOROPLETH: 'choropleth',
	TRENDS: 'trends'
};

const FORM_ELEMENTS_NAMES = {
	ISP: 'ispID',
	ACCESS_TYPE: 'accessTypeID',
	TECHNOLOGY_TYPE: 'technologyID',
	START_DATE: 'startDate',
	END_DATE: 'endDate',
	ADMINISTRATIVE_LEVELS: 'administrativeLevelID',
	TOWERS: 'towersID'
};

const FORM_ROWS_ID = {
	ISP: 'isp',
	ACCESS_TYPE: 'accessType',
	TECHNOLOGY_TYPE: 'technology',
	DATE_RANGE: 'dateRange',
	ADMINISTRATIVE_LEVELS: 'administrativeLevels',
	TOWERS: 'towers'
}

const ISP_CODES = {
	BATELCO: 42601,
	ZAIN: 42602,
	STC: 42604
}

const MIN_DATE = '1 April, 2021';

export default class TRAUIMap {

	constructor() {
		this.apiController = apiController;
		this.helpers = helpers;
		this.nls = nls;

		this.properties = {
			options: null,
			type: null,
			formFiltersRendered: {
				accessType: false,
				technology: false,
				isp: false
			},
			formRendered: false,
			queryParams: null,
			locationFromUrl: {
				lat: null,
				lng: null
			},
			ispFromUrl: null,
			averageSpeed: null,
			debug: false,
			startDateFromUrl: null, // custom startDate parameter from url in debug mode
			endDateFromUrl: null, // custom endDate parameter from url in debug mode

			previousProperties: {
				[MAP_TYPES.HEAT]: null,
				[MAP_TYPES.CHOROPLETH]: null,
				[MAP_TYPES.TRENDS]: null
			},
			choroplethFirstLoad: true,

			accessTypeList: [],
			technologyTypeList: [],
			ispTypeList: [],
		};

		this.elements = {
			form: null,
			btnInternetSpeeds: null,
			btnNetworkCoverage: null,
			formFilters: null,
			btnNetworkCoverage: null,
			btnFilter: null
		};
	}

	init(options) {
		this.properties.options = options;
		// console.log('init', options);

		this.setDebug();
		this.setPeriod();
		this.setLanguage();
		this.setLocation();
		this.setISP();
		this.setAverageSpeedInfo();

		this.rednerIframe();
		this.getElements();
		this.initEvents();
		this.translate();
	}

	setDebug() {
		// console.log('setDebug');
		const debug = new URLSearchParams(window.location.search).get('debug');
		this.properties.debug = !!debug;
	}

	setPeriod() {
		// console.log('setPeriod');
		if (this.properties.debug) {
			const startDate = new URLSearchParams(window.location.search).get('startDate');
			if (startDate) {
				this.properties.startDateFromUrl = startDate;
			}

			const endDate = new URLSearchParams(window.location.search).get('endDate');
			if (endDate) {
				this.properties.endDateFromUrl = endDate;
			}
		}
	}

	setLanguage() {
		// console.log('setLanguage');
		const lang = new URLSearchParams(window.location.search).get('lang');
		this.properties.lang = lang != undefined ? lang : 'fr';
	}

	setLocation() {
		// console.log('setLocation');
		const lat = new URLSearchParams(window.location.search).get('lat');
		const lng = new URLSearchParams(window.location.search).get('lng');

		if (lat != undefined && lng != undefined) {
			this.properties.locationFromUrl.lat = lat;
			this.properties.locationFromUrl.lng = lng;
		}
	}

	setISP() {
		// console.log('setISP');
		const ispCode = new URLSearchParams(window.location.search).get('isp');
		if (ispCode != undefined) {
			let isp = null;
			for (let prop in ISP_CODES) {
				if (ISP_CODES[prop] == ispCode) {
					isp = prop.toLowerCase();
				}
			}

			if (isp != null) {
				this.properties.ispFromUrl = isp;
			}
		}
	}

	setAverageSpeedInfo() {
		// console.log('setAverageSpeedInfo');
		const averageSpeed = new URLSearchParams(window.location.search).get('averageSpeed');
		this.properties.averageSpeed = averageSpeed != undefined ? averageSpeed : null;
	}

	rednerIframe() {
		// console.log('rednerIframe');
		let iframe = document.createElement('iframe');
		let iframeUrl = this.properties.options.iframeUrl;
		iframe.setAttribute('src', iframeUrl);
		iframe.setAttribute('id', 'scTraIframe');


		let body = document.querySelector('body');
		body.appendChild(iframe);
	}

	getElements() {
		// console.log('getElements');
		this.elements.mapContainer = document.getElementById('mapContainer');
		this.elements.title = document.getElementById('title');
		this.elements.form = document.getElementById('formFilters');
		this.elements.iframe = document.getElementById('scTraIframe');
		this.elements.btnNetworkCoverage = document.getElementById('btnNetworkCoverage');
		this.elements.btnInternetSpeeds = document.getElementById('btnInternetSpeeds');
		this.elements.formFilters = document.getElementById('formFilters');
		this.elements.btnFilter = document.querySelectorAll('.btn-filter');
	}

	initEvents() {
		// console.log('initEvents');
		this.elements.btnNetworkCoverage.addEventListener('click', (e) => {
			e.preventDefault();
			const options = {
				type: MAP_TYPES.HEAT
			};
			this.saveSelectedProperties(MAP_TYPES.HEAT);
			this.createMap(options);
		});

		this.elements.btnInternetSpeeds.addEventListener('click', (e) => {
			e.preventDefault();
			const options = {
				type: MAP_TYPES.CHOROPLETH
			};

			this.saveSelectedProperties(MAP_TYPES.CHOROPLETH);
			this.createMap(options);
			this.properties.choroplethFirstLoad = false;
		});

		// on form change
		this.elements.formFilters.addEventListener('change', (e) => {
			e.preventDefault();
			this.updateForm();
			this.updateMap();
		});

		window.addEventListener('resize', () => {
			this.setMapHeight();
		});

		window.addEventListener('sc_tra_iframeCreated', (e) => {
			this.saveSelectedProperties();
			this.createMap(this.properties.options);
		});

		window.addEventListener('sc_tra_choroplethMap_updateAdminLevelRequired', (e) => {
			if (e.detail && e.detail.index != null) {
				this.selectAdministrativeLevel(e.detail.index);
			}
		});

		for (let index = 0; index < this.elements.btnFilter.length; index++) {
			const element = this.elements.btnFilter[index];
			element.addEventListener('click', (e) => {
				e.preventDefault();

				for (let index = 0; index < this.elements.btnFilter.length; index++) {
					const element = this.elements.btnFilter[index];
					element.classList.remove('active');
				}
				e.target.classList.add('active');
			});
		}
	}

	translate() {
		// console.log('translate');
		this.nls.translate(this.properties.lang);
	}

	/**
	 * Show/hide elements on the form if form was changed
	 */
	updateForm() {
		// hide technology filter if wifi selected
		let queryParamsUI = this.helpers.getQueryParamsFromUI(this.elements.form);
		let technologyTypeElement = document.getElementById(FORM_ROWS_ID.TECHNOLOGY_TYPE);
		let technologyTypeCustomElement = document.querySelector('.row-filter-technology');
		// let towersElement = document.querySelector('.row-filter-towers');

		if (!technologyTypeElement || !technologyTypeCustomElement) {
			return;
		}

		// hide technology type and towers filter for all maps except heatmap
		if (this.properties.type !== MAP_TYPES.HEAT) {
			technologyTypeElement.classList.add('hidden');
			technologyTypeCustomElement.classList.add('hidden');
			// towersElement.classList.add('hidden');
		} else {
			if (queryParamsUI.accessTypeID == 1) {
				technologyTypeElement.classList.add('hidden');
				technologyTypeCustomElement.classList.add('hidden');
			} else {
				technologyTypeElement.classList.remove('hidden');
				technologyTypeCustomElement.classList.remove('hidden');
			}
			// towersElement.classList.remove('hidden');
		}

		// TODO improve
		// hide 'All' options in Access type if map type is not choropleth
		let choroplethElementsOnly = document.querySelectorAll(`#${FORM_ROWS_ID.ACCESS_TYPE}, .row-filter-administrative`);
		if (choroplethElementsOnly != undefined && choroplethElementsOnly.length) {
			if (this.properties.type === MAP_TYPES.CHOROPLETH || this.properties.type === MAP_TYPES.TRENDS) {
				for (let index = 0; index < choroplethElementsOnly.length; index++) {
					const element = choroplethElementsOnly[index];
					element.classList.remove('hidden');
				}
			} else {
				for (let index = 0; index < choroplethElementsOnly.length; index++) {
					const element = choroplethElementsOnly[index];
					element.classList.add('hidden');
				}
			}

		}
	}

	renderTitle() {
		let attrValue = '';
		switch (this.properties.type) {
			case MAP_TYPES.HEAT:
				attrValue = 'titles.networkCoverage'
				break;

			case MAP_TYPES.CHOROPLETH:
				attrValue = 'titles.internetSpeeds'
				break;

			default:
				break;
		}
		this.elements.title.innerHTML = this.nls.getTranslationByAttr(attrValue, this.properties.lang);
	}

	createMap(options) {
		// console.log('createMap', options);
		// // prevent double creation the same map
		if (this.properties.type === options.type) {
			return;
		}
		this.properties.type = options.type;

		this.reset();
		this.renderTitle();

		this.renderForm().then(() => {
			this.translate();
			this.updateForm();
			this.setMapHeight();

			this.loadPreviousProperties();

			// set technology type filter to "" for all maps except heatmap
			let elementsToSkip = [];
			if (this.properties.type !== MAP_TYPES.HEAT) {
				elementsToSkip.push(FORM_ELEMENTS_NAMES.TECHNOLOGY_TYPE);
			}

			this.properties.queryParams = this.helpers.getQueryParamsFromUI(this.elements.form, elementsToSkip);


			const event = new CustomEvent('sc_tra_createMap', {
				detail: {
					type: this.properties.type,
					iframeFolderUrl: this.properties.options.iframeFolderUrl,
					lang: this.properties.lang,
					locationFromUrl: this.properties.locationFromUrl,
					startDateFromUrl: this.properties.startDateFromUrl,
					endDateFromUrl: this.properties.endDateFromUrl,
					queryParams: this.properties.queryParams,
					averageSpeed: this.properties.averageSpeed,
					debug: this.properties.debug,
					accessTypeList: this.properties.accessTypeList,
					technologyTypeList: this.properties.technologyTypeList,
					ispTypeList: this.properties.ispTypeList
				}
			});
			window.dispatchEvent(event);
		});
	}

	updateMap() {

		// set technology type filter to "" for all maps except heatmap
		let elementsToSkip = [];
		if (this.properties.type !== MAP_TYPES.HEAT) {
			elementsToSkip.push(FORM_ELEMENTS_NAMES.TECHNOLOGY_TYPE);
		}
		this.properties.queryParams = this.helpers.getQueryParamsFromUI(this.elements.form, elementsToSkip);
		this.properties.loading = true;

		const event = new CustomEvent('sc_tra_updateMap', {
			detail: {
				queryParams: this.properties.queryParams,
				debug: this.properties.debug
			}
		});
		window.dispatchEvent(event);
	}

	reset() {
		this.properties.queryParams = null;
	}

	renderForm() {
		return new Promise((resolve, reject) => {

			if (this.properties.formRendered) {
				resolve();
			} else {
				this.elements.form.innerHTML = '';

				this.renderFilterAccess().then(() => this.checkFormRendered(resolve, reject));
				this.renderFilterTechnology().then(() => this.checkFormRendered(resolve, reject));
				this.renderFilterISP().then(() => this.checkFormRendered(resolve, reject));
				this.renderFilterAdministrativeLevels().then(() => this.checkFormRendered(resolve, reject));

				this.renderMonthPicker();
				// this.renderCheckboxTowers();
			}
		});
	}

	/** Set map height after form was rendered. height = bodyHeight - formHeight. Page should be without scroll */
	setMapHeight() {
		const height = this.helpers.calculateMapHeight(this.elements.iframe);
		this.elements.iframe.setAttribute('style', `height: ${height}px`);
	}

	renderFilterAccess() {
		return new Promise((resolve, reject) => {
			apiController.getAccessTypeList()
				.then((data) => {
					this.properties.accessTypeList = data;

					this.renderHtml(data, {
						type: 'radio',
						name: FORM_ELEMENTS_NAMES.ACCESS_TYPE,
						css: 'order-1',
						id: FORM_ROWS_ID.ACCESS_TYPE,
						indexToSelect: 1,
						nlsAttributeValues: {
							// title of the label
							title: 'inputs.accessType.title',

							/* 
							 * HARDCODED
							 * 
							 * Translations for radioelements. 1 - is ID of wifi value from api. 2 - is ID of cellular value from api
							 */
							elements: {
								1: 'inputs.accessType.wifi',
								2: 'inputs.accessType.cellular',
								99999: 'inputs.accessType.both'
							}
						}
					});

					this.properties.formFiltersRendered.accessType = true;

					resolve();
				}).catch((error) => {
					console.error('Request to API.ACCESS_TYPE_LIST failed.', error);
				});
		});
	}

	renderFilterTechnology() {
		return new Promise((resolve, reject) => {
			apiController.getWifiValueTypeList()
				.then((data) => {
					this.properties.technologyTypeList = data;
					let additionalHtml = null;
					// let options5G = {
					// 	type: 'checkbox',
					// 	name: 'custom5G',
					// 	css: 'order-3',
					// 	id: 'custom5G'
					// };

					if (this.properties.debug) {
						additionalHtml = null;
					}

					let options = {
						// type: 'radio',
						type: 'select',
						name: FORM_ELEMENTS_NAMES.TECHNOLOGY_TYPE,
						css: 'order-2 row-filter-technology',
						additionalFields: [{
							ID: '',
							Value: 'Tout'
						}],
						additionalHtml: additionalHtml,
						id: FORM_ROWS_ID.TECHNOLOGY_TYPE,
						nlsAttributeValues: {
							// title of the label
							title: 'inputs.technology.title',

							/* 
							 * HARDCODED
							 * 
							 * Translations for radioelements. 2 - is ID of 2g value from api. 3 - 3g, 4g, 5 - 5g
							 */
							elements: {
								2: 'inputs.technology.2g',
								3: 'inputs.technology.3g',
								4: 'inputs.technology.4g',
								5: 'inputs.technology.5g'
							}
						}
					}

					if (this.properties.options.hidden5g) {
						options.hiddenValue = '5g';
					}

					this.renderHtml(data, options);

					this.properties.formFiltersRendered.technology = true;


					// on click 5g
					// TODO improve logic
					// let label5Gmock = document.querySelector('[label-mock]');
					// if (label5Gmock) {
					// 	label5Gmock.addEventListener('click', (e) => {
					// 		// alert('5G coverage maps are not yet available.');
					// 		let modal = document.getElementById("traModal");
					// 		modal.style.display = "block";
					// 		let content = document.querySelector('#traModal .content');
					// 		content.innerHTML = '5G coverage maps are not yet available.';
					// 	});
					// }

					resolve();

				}).catch((error) => {
					console.error('Request to API.TECHNOLOGY_TYPE_LIST failed.', error);
				});
		});
	}

	renderFilterISP() {
		return new Promise((resolve, reject) => {
			apiController.getISPList()
				.then((data) => {
					let ispList = data.filter(e => e.Value == 'All').concat(data.filter(e => e.Value != 'All'));
					ispList = ispList.map(e => {
						if (e.Value == 'All') {
							e.Value = 'Tout';
						}

						return e;
					})
					this.properties.ispTypeList = ispList;

					this.renderHtml(ispList, {
						type: 'select',
						name: FORM_ELEMENTS_NAMES.ISP,
						css: 'order-3 row-filter-isp',
						id: FORM_ROWS_ID.ISP,
						nlsAttributeValues: {
							// title of the label
							title: 'inputs.isp.title',
						}
					});

					this.properties.formFiltersRendered.isp = true;

					resolve();

				}).catch((error) => {
					console.error('Request to API.ISP_LIST failed.', error);
				});
		});
	}

	renderFilterAdministrativeLevels() {
		return new Promise((resolve, reject) => {
			apiController.getAdministrativeLevelsList()
				.then((data) => {
					// TODO
					// this.properties.ispTypeList = data;

					this.renderHtml(data, {
						type: 'select',
						name: FORM_ELEMENTS_NAMES.ADMINISTRATIVE_LEVELS,
						css: 'order-2 row-filter-administrative',
						id: FORM_ROWS_ID.ADMINISTRATIVE_LEVELS,
						nlsAttributeValues: {
							// title of the label
							title: 'inputs.administrativeLevels.title',
						}
					});

					// TODO
					// this.properties.formFiltersRendered.isp = true;

					// TODO save to last properties


					resolve();

				}).catch((error) => {
					console.error('Request to API.ISP_LIST failed.', error);
				});
		});
	}

	renderCheckboxTowers() {
		const data = [{ "ID": 1, "Value": "Montrer les Tours" }];
		this.renderHtml(data, {
			type: 'checkbox',
			css: 'order-4 row-filter-towers',
			name: FORM_ELEMENTS_NAMES.TOWERS,
			id: FORM_ROWS_ID.TOWERS,
		});
	}

	renderMonthPicker() {

		const data = this.helpers.getMonthPickerData(new Date(MIN_DATE), this.nls.getTranslationByAttr('inputs.monthPicker.months', this.properties.lang));
		this.renderHtml(data, {
			type: 'monthPicker',
			nameStartDate: FORM_ELEMENTS_NAMES.START_DATE,
			nameEndDate: FORM_ELEMENTS_NAMES.END_DATE,
			css: 'order-5 row-filter-month-picker',
			width: 150,
			id: FORM_ROWS_ID.DATE_RANGE,
			nlsAttributeValues: {
				// title of the label
				title: 'inputs.monthPicker.title'
			}
		});
	}

	// render html from response
	renderHtml(data, options) {
		switch (options.type) {
			case 'checkbox':
				this.renderHtmlCheckbox(data, options);
				break;

			case 'radio':
				this.renderHtmlRadio(data, options);
				break;

			case 'select':
				this.renderHtmlSelect(data, options);
				break;

			case 'monthPicker':
				this.renderHtmlMonthPicker(data, options);
				break;

			default:
				break;
		}
	}

	renderHtmlRadio(data, options) {
		let div = document.createElement('div');
		div.innerHTML = this.helpers.getHtmlRadio(data, options);

		if (options.additionalHtml) {
			div.innerHTML += options.additionalHtml;
		}
		div.setAttribute('class', `row-filter ${options.css}`);
		div.setAttribute('id', options.id);

		this.elements.form.appendChild(div);
	}

	renderHtmlCheckbox(data, options) {
		let div = document.createElement('div');
		div.innerHTML = this.helpers.getHtmlCheckbox(data, options);

		if (options.additionalHtml) {
			div.innerHTML += options.additionalHtml;
		}
		div.setAttribute('class', `row-filter ${options.css}`);
		div.setAttribute('id', options.id);

		this.elements.form.appendChild(div);
	}

	renderHtmlSelect(data, options) {
		this.renderHtmlSelectNative(data, options);
		this.renderHtmlSelectCustom();
	}

	renderHtmlSelectNative(data, options) {
		let div = document.createElement('div');
		div.setAttribute('class', `row-filter ${options.css}`);
		div.innerHTML = this.helpers.getHtmlStringSelectNative(data, options);
		this.elements.form.appendChild(div);
	}

	//taken and customized https://www.w3schools.com/howto/howto_custom_select.asp
	renderHtmlSelectCustom() {
		var self = this;
		var x, i, j, selElmnt, a, b, c;
		/* Look for any elements with the class "custom-select": */
		x = document.getElementsByClassName("custom-select");
		for (i = 0; i < x.length; i++) {
			if (x[i].classList.contains('rendered')) {
				continue;
			}


			selElmnt = x[i].getElementsByTagName("select")[0];
			/* For each element, create a new DIV that will act as the selected item: */
			a = document.createElement("DIV");
			a.setAttribute("class", "select-selected");
			a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
			x[i].appendChild(a);
			/* For each element, create a new DIV that will contain the option list: */
			b = document.createElement("DIV");
			b.setAttribute("class", "select-items select-hide");
			for (j = 0; j < selElmnt.length; j++) {
				/* For each option in the original select element,
				create a new DIV that will act as an option item: */
				c = document.createElement("DIV");
				c.innerHTML = selElmnt.options[j].innerHTML;
				c.addEventListener("click", function (e) {
					/* When an item is clicked, update the original select box,
					and the selected item: */
					var y, i, k, s, h;
					s = this.parentNode.parentNode.getElementsByTagName("select")[0];
					h = this.parentNode.previousSibling;
					for (i = 0; i < s.length; i++) {
						if (s.options[i].innerHTML == this.innerHTML) {
							s.selectedIndex = i;
							h.innerHTML = this.innerHTML;
							y = this.parentNode.getElementsByClassName("same-as-selected");
							for (k = 0; k < y.length; k++) {
								y[k].removeAttribute("class");
							}
							this.setAttribute("class", "same-as-selected");
							break;
						}
					}
					h.click();

					// fire event form change
					const event = new Event('change');
					self.elements.form.dispatchEvent(event);
				});
				b.appendChild(c);
			}
			x[i].appendChild(b);
			x[i].classList.add('rendered');

			a.addEventListener("click", function (e) {
				/* When the select box is clicked, close any other select boxes,
				and open/close the current select box: */
				e.stopPropagation();
				self.closeAllSelect(this);
				this.nextSibling.classList.toggle("select-hide");
				this.classList.toggle("select-arrow-active");
			});
		}


		/* If the user clicks anywhere outside the select box,
		then close all select boxes: */
		document.addEventListener("click", self.closeAllSelect);
	}

	renderHtmlMonthPicker(data, options) {
		this.renderHtmlMonthPickerNative(data, options);
		this.renderHtmlMonthPickerCustom(data, options);
	}

	renderHtmlMonthPickerNative(data, options) {
		let div = document.createElement('div');
		div.setAttribute('class', `relative row-filter ${options.css}`);
		div.innerHTML = this.helpers.getHtmlStringMonthPickerNative(data, options);
		this.elements.form.appendChild(div);
	}

	renderHtmlMonthPickerCustom(data, options) {
		let dateRangeElement = document.getElementById(FORM_ROWS_ID.DATE_RANGE);

		let monthPickerTrigger = document.createElement('button');
		monthPickerTrigger.setAttribute('class', 'month-picker-trigger');

		let monthPickerSelect = document.createElement('div');
		monthPickerSelect.setAttribute('class', 'month-picker-select');

		let monthPickerClose = document.createElement('div');
		monthPickerClose.setAttribute('class', 'month-picker-close');
		monthPickerSelect.appendChild(monthPickerClose);

		data.forEach((element, index) => {

			// set initial values

			if (element.selected) {
				monthPickerTrigger.innerHTML = element.label;

				let inputStartDate = document.querySelector(`#${options.id} input[name="${options.nameStartDate}"]`);
				let inputEndDate = document.querySelector(`#${options.id} input[name="${options.nameEndDate}"]`);
				inputStartDate.value = element.startDate;
				inputEndDate.value = element.endDate;
			}

			let monthPickerOption = document.createElement('div');
			monthPickerOption.setAttribute('class', 'month-picker-option');
			monthPickerOption.setAttribute('data-start-date', element.startDate);
			monthPickerOption.setAttribute('data-end-date', element.endDate);

			monthPickerOption.innerHTML = element.label;
			monthPickerSelect.appendChild(monthPickerOption);

			// event listeners

			monthPickerOption.addEventListener('click', (e) => {
				e.preventDefault();

				if (monthPickerTrigger.innerHTML != monthPickerOption.innerHTML) {
					// set date to inputs

					let inputStartDate = document.querySelector(`#${options.id} input[name="${options.nameStartDate}"]`);
					let inputEndDate = document.querySelector(`#${options.id} input[name="${options.nameEndDate}"]`);

					let startDate = monthPickerOption.getAttribute('data-start-date');
					let endDate = monthPickerOption.getAttribute('data-end-date');

					inputStartDate.value = startDate;
					inputEndDate.value = endDate;


					// close month picker

					monthPickerTrigger.innerHTML = monthPickerOption.innerHTML;

					// fire event form change
					const event = new Event('change');
					this.elements.form.dispatchEvent(event);
				}

				monthPickerSelect.classList.remove('active');

			});
		});

		dateRangeElement.appendChild(monthPickerSelect);
		dateRangeElement.appendChild(monthPickerTrigger);


		// event listeners

		monthPickerTrigger.addEventListener('click', (e) => {
			e.preventDefault();
			monthPickerSelect.classList.toggle('active');
		});


		monthPickerClose.addEventListener('click', (e) => {
			e.preventDefault();
			monthPickerSelect.classList.remove('active');
		});

		/* If the user clicks anywhere outside the month picker,
		then close month picker: */
		document.addEventListener("click", (e) => {
			if (!e.target.classList.contains("month-picker-trigger")) {
				monthPickerSelect.classList.remove('active');
			}
		});
	}

	closeAllSelect(elmnt) {
		/* A function that will close all select boxes in the document,
		except the current select box: */
		var x, y, i, arrNo = [];
		x = document.getElementsByClassName("select-items");
		y = document.getElementsByClassName("select-selected");
		for (i = 0; i < y.length; i++) {
			if (elmnt == y[i]) {
				arrNo.push(i);
			} else {
				y[i].classList.remove("select-arrow-active");
			}
		}
		for (i = 0; i < x.length; i++) {
			if (arrNo.indexOf(i)) {
				x[i].classList.add("select-hide");
			}
		}
	}

	checkFormRendered(resolve, reject) {
		let result = true;
		for (let prop in this.properties.formFiltersRendered) {
			if (!this.properties.formFiltersRendered[prop]) {
				result = false;
			}
		}
		if (result) {
			this.properties.formRendered = true;
			resolve();
		}

		return;
	}

	saveSelectedProperties(nextMapType) {
		if (this.properties.type == undefined) {
			return;
		}

		this.properties.previousProperties[this.properties.type] = this.properties.queryParams;
		// copy properties from choropleth to trends
		if (this.properties.type == MAP_TYPES.CHOROPLETH) {
			this.properties.previousProperties[MAP_TYPES.TRENDS] = this.properties.previousProperties[MAP_TYPES.CHOROPLETH];

			if (nextMapType != undefined && nextMapType == MAP_TYPES.HEAT) {
				this.copyPreviousPropertiesToHeatmap();
			}
		}

		// copy properties from trends to choropleth
		else if (this.properties.type == MAP_TYPES.TRENDS) {
			this.properties.previousProperties[MAP_TYPES.CHOROPLETH] = this.properties.previousProperties[MAP_TYPES.TRENDS];

			if (nextMapType != undefined && nextMapType == MAP_TYPES.HEAT) {
				this.copyPreviousPropertiesToHeatmap();
			}
		}

		// copy properties from heat to trends and choropleth
		else if (this.properties.type == MAP_TYPES.HEAT && nextMapType != undefined && !this.properties.choroplethFirstLoad) {
			this.copyPreviousPropertiesToChoropleth();
		}
	}

	loadPreviousProperties() {
		let previousProperties = this.properties.previousProperties[this.properties.type];

		if (!previousProperties || previousProperties == undefined) {

			// click on isp defined in url
			if (this.properties.ispFromUrl != undefined) {
				this.selectISP(this.properties.ispFromUrl);
			}

			// click on technology type:all and isp:all for internet speed map and usage trends map
			else if (
				(this.properties.type === MAP_TYPES.CHOROPLETH && this.properties.previousProperties[MAP_TYPES.CHOROPLETH] == undefined)
				|| (this.properties.type === MAP_TYPES.TRENDS && this.properties.previousProperties[MAP_TYPES.TRENDS] == undefined)
			) {
				let choroplethElementsOnly = document.querySelectorAll(`#${FORM_ROWS_ID.ACCESS_TYPE} label:nth-last-of-type(1)`);
				if (choroplethElementsOnly != undefined && choroplethElementsOnly.length) {
					for (let index = 0; index < choroplethElementsOnly.length; index++) {
						const element = choroplethElementsOnly[index];
						element.click();
					}
				}
			}


			return;
		}

		// console.log('this.properties.previousProperties', this.properties.previousProperties);

		// select Access Type
		if (previousProperties[FORM_ELEMENTS_NAMES.ACCESS_TYPE]) {
			// console.log('previousProperties[FORM_ELEMENTS_NAMES.ACCESS_TYPE]', previousProperties[FORM_ELEMENTS_NAMES.ACCESS_TYPE]);

			let elementsToClick = document.querySelectorAll(`#${FORM_ROWS_ID.ACCESS_TYPE} label input[type="radio"]`);
			if (elementsToClick != undefined) {
				for (let index = 0; index < elementsToClick.length; index++) {
					const element = elementsToClick[index];

					if (element.getAttribute('value') == previousProperties[FORM_ELEMENTS_NAMES.ACCESS_TYPE]) {
						console.log('click');
						console.log('element', element, element.getAttribute('value'));
						element.click();
					}
				}
			}
		}

		// select Technology
		if (previousProperties[FORM_ELEMENTS_NAMES.TECHNOLOGY_TYPE]) {
			// console.log('previousProperties[FORM_ELEMENTS_NAMES.TECHNOLOGY_TYPE]', previousProperties[FORM_ELEMENTS_NAMES.TECHNOLOGY_TYPE]);

			let elementsToClick = document.querySelectorAll(`#${FORM_ROWS_ID.TECHNOLOGY_TYPE} label input[type="radio"]`);
			if (elementsToClick != undefined) {
				for (let index = 0; index < elementsToClick.length; index++) {
					const element = elementsToClick[index];

					if (element.getAttribute('value') == previousProperties[FORM_ELEMENTS_NAMES.TECHNOLOGY_TYPE]) {
						// console.log('click');
						// console.log('element', element, element.getAttribute('value'));
						element.click();
						break;
					}
				}
			}
		}

		// select ISP
		if (previousProperties[FORM_ELEMENTS_NAMES.ISP]) {
			// console.log('previousProperties[FORM_ELEMENTS_NAMES.ISP]', previousProperties[FORM_ELEMENTS_NAMES.ISP]);

			// get name of ISP by value
			let options = document.querySelectorAll(`[name="${FORM_ELEMENTS_NAMES.ISP}"] option`);
			let ispName = null;

			if (options != undefined) {
				for (let index = 0; index < options.length; index++) {
					const element = options[index];
					if (element.getAttribute('value') == previousProperties[FORM_ELEMENTS_NAMES.ISP]) {
						ispName = element.innerHTML.trim();
						break;
					}
				}
			}

			this.selectISP(ispName);
		}
	}

	copyPreviousPropertiesToHeatmap() {

		// if access type != 99999 ('all'), then copy property. If == 99999, don't copy property
		const accessTypeValue = this.properties.previousProperties[MAP_TYPES.CHOROPLETH][FORM_ELEMENTS_NAMES.ACCESS_TYPE];
		if (accessTypeValue != 99999) {
			this.properties.previousProperties[MAP_TYPES.HEAT][FORM_ELEMENTS_NAMES.ACCESS_TYPE] = accessTypeValue;
		}

		// if isp != 99999 ('all'), then copy property. If == 99999, don't copy property
		const ispValue = this.properties.previousProperties[MAP_TYPES.CHOROPLETH][FORM_ELEMENTS_NAMES.ISP];
		if (ispValue != 99999) {
			this.properties.previousProperties[MAP_TYPES.HEAT][FORM_ELEMENTS_NAMES.ISP] = ispValue;
		}
	}

	copyPreviousPropertiesToChoropleth() {
		const accessTypeValue = this.properties.previousProperties[MAP_TYPES.HEAT][FORM_ELEMENTS_NAMES.ACCESS_TYPE];
		this.properties.previousProperties[MAP_TYPES.CHOROPLETH][FORM_ELEMENTS_NAMES.ACCESS_TYPE] = accessTypeValue;
		this.properties.previousProperties[MAP_TYPES.TRENDS][FORM_ELEMENTS_NAMES.ACCESS_TYPE] = accessTypeValue;

		const ispValue = this.properties.previousProperties[MAP_TYPES.HEAT][FORM_ELEMENTS_NAMES.ISP];
		this.properties.previousProperties[MAP_TYPES.CHOROPLETH][FORM_ELEMENTS_NAMES.ISP] = ispValue;
		this.properties.previousProperties[MAP_TYPES.TRENDS][FORM_ELEMENTS_NAMES.ISP] = ispValue;
	}

	selectISP(ispName) {
		// find isp by name
		if (ispName != undefined) {
			// console.log('ispName', ispName, ispName.length);

			let elementsToClick = document.querySelectorAll(`.row-filter-isp .custom-select .select-items div`);
			// console.log('elementsToClick', elementsToClick);

			if (elementsToClick != undefined) {
				for (let index = 0; index < elementsToClick.length; index++) {
					const element = elementsToClick[index];
					if (element.innerHTML.toLowerCase().trim() == ispName.toLowerCase()) {
						// console.log('click', element);
						element.click();
					}
				}
			}

		}
	}

	selectAdministrativeLevel(index) {
		console.log('selectAdministrativeLevel', index);
		// find administrativeLevel by value
		if (index != undefined) {
			let elementsToClick = document.querySelectorAll(`.row-filter-administrative .custom-select .select-items div`);
			const element = elementsToClick[index];
			element.click();
		}
	}
}

