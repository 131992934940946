const URL = {
    ACCESS_TYPE_LIST: 'https://maps-artp-sn.speedchecker.com/Map/GetAccessTypeList',
    WIFI_VALUE_TYPE_LIST: 'https://maps-artp-sn.speedchecker.com/Map/GetWifiValueTypeList',
    ISP_LIST: 'https://maps-artp-sn.speedchecker.com/Map/GetISPList',
    SPEED_VALUE_TYPE_LIST: 'https://maps-artp-sn.speedchecker.com/Map/GetSpeedValueTypeList'
};

export default {
    getAccessTypeList() {
        return sendRequest({
            method: 'GET',
            url: URL.ACCESS_TYPE_LIST
        });
    },

    getWifiValueTypeList() {
        return sendRequest({
            method: 'GET',
            url: URL.WIFI_VALUE_TYPE_LIST
        });
    },

    // REAL
    getISPList() {
        return sendRequest({
            method: 'GET',
            url: URL.ISP_LIST
        });
    },

    // FAKE
    // getISPList() {
    //     let shortList = [
    //         "All",
    //         "Expresso",
    //         "Orange",
    //         "Free"
    //     ];

    //     let result = [];

    //     shortList.forEach(element => {
    //         result.push({
    //             ID: element,
    //             Value: element
    //         })
    //     });
    //     console.log('getISPList', result);

    //     return new Promise((resolve, reject) => {
    //         resolve(result);
    //     });
    // },

    // FAKE
    getAdministrativeLevelsList() {
        return new Promise((resolve, reject) => {
            let result = [
                { "ID": 1, "Value": "régions" },
                { "ID": 2, "Value": "départements" },
                { "ID": 3, "Value": "arrondissements" },
                { "ID": 4, "Value": "communes" }
            ];
            resolve(result);
        });
    },
};

function sendRequest(options) {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        let url = options.url;

        if (options.method === 'GET' && options.queryParams != undefined) {
            const queryString = new URLSearchParams(options.queryParams);
            url += '?' + queryString;
        }

        xhr.open(options.method, url);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function () {
            if (xhr.readyState == XMLHttpRequest.DONE) {
                if (xhr.status == 200) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    reject();
                }
            }
        };

        switch (options.method) {
            case 'GET':
                xhr.send();
                break;

            case 'POST':
                xhr.send(options.data);
                break;

            default:
                break;
        }
    });
}